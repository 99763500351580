$as_breakpoint_lg: 1118px;

/* Analyte Search */
.page-analyte-search {
  #zone2 {
    padding-top: 15px;
  }

  p.description {
    font-size: 11px;
    line-height: 14px;
    color: gray;
  }
}

#block-views-exp-analyte-search-page{
  margin-top: 5px;
}

#block-views-exp-analyte-search-page .blocktitle, [id^="block-facetapi-"] .blocktitle{
  background-color: #002876;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 10px;
}

#facetapi-facet-search-apianalyte-search-block-field-analytes {
  max-height: 150px;
  overflow-y: auto; }

.form-item-field-analytes, .form-item-field-plex, .form-item-field-species{
  margin-top: 0em;
  margin-bottom: 0em;
}

#block-views-exp-analyte-search-page, #block-facetapi-mcwad9dwsg6fyyoh2zpxpt2xf8vc0aos, #block-facetapi-jwpefevrc25i1t7mu7tpvlpwq1umnkga, #block-facetapi-1r7i9x1kdmu0aci9ezazkwmrthdxuurc, #block-facetapi-w7pjsrymlzfnibsk0qxnox0yveojns2z{
  margin-bottom: 0px !important;
}

#block-system-main > div > div.view-analyte-search > div.view-content {
  max-height: 575px;
  overflow-y: scroll;
  overflow-x: hidden;
}

#block-system-main > div > div.view-analyte-search > div.view-content.expanded {
  max-height: 400px;
}

#block-system-main > div > div.view-analyte-search > div.view-content > table > thead{
  display: block;
}

// Allow the table body to scroll
#block-system-main > div > div.view-analyte-search > div.view-content > table > tbody  {
  max-height: 600px;
  display: block;
  overflow-y: scroll;
  overflow-x: hidden;
}
// Keep search bar width constrained
.views-widget-filter-search_api_views_fulltext {
  width: 96%
}

#views-exposed-form-analyte-search-page {
  .views-exposed-widget {
    padding: 0;
  }
  input.btn {
    margin: 15px 10px 0 0;
    width: 100px;
  }
}

// Preview Button Style

.btn-theme > a {
  color: #ffffff;
}

// Zebra stripe rows like a table would
.view-analyte-search .views-row-odd {
  background: #eee;
}

.view-analyte-search .views-row {
  padding: 5px 10px;
}

// Assay Service - Analyte Search Result View Mode
.analyte-search-result {
  padding: 5px 0;

  @include breakpoint($as_breakpoint_lg) {
    display: flex;
  }

  &__title {
    @include breakpoint($as_breakpoint_lg) {
      width: 300px;
    }
  }

  &__terms {
    display: flex;
    flex-wrap: wrap;
  }

  &__term {
    width: 75px;
    min-width: 75px;
    margin-right: 10px;
    margin-top: 10px;

    @include breakpoint($as_breakpoint_lg) {
      margin: 0 20px 0 0;
    }

    .btn {
      width: 100%;
      box-sizing: border-box;
      background: rgb(40,110,160);
      background: linear-gradient(54deg, rgba(40,110,160,1) 0%, rgba(55,166,231,1) 50%, rgba(0,212,255,1) 100%);
      border-color: rgb(116, 179, 224);
    }
  }

  &__ghost-btn {
    width: 75px;
    height: 24px;
    border: 1px dashed #ccc;
    border-radius: 2px;
  }
}

.view-analyte-search .preview-container {

  h2 {
    margin-bottom: 10px;
  }

  &__analytes {
    margin-bottom: 0px;
    span {
      &:after {
        content: ', '
      }
  
      &:last-child:after {
        content: '';
      }
    }
  }

  &__description {
    margin: 10px 0 20px 0;
  }
}

.analyte-search__header {
  margin-top: 10px;
  font-weight: bold;
  background: #002876;
  color: white;
  padding: 5px 10px;

  display: flex;

  .desc-col {
    width: 300px;
  }

  .as-col {
    width: 75px;
    margin-right: 1.44em;
    text-align: center;
    display: none;

    @include breakpoint($as_breakpoint_lg) {
      display: block;
    }
  }
}

// Contact CTA below the view.
.analyte-search__contact {
  margin-top: 20px;
  padding: 5px 10px;
  background: #efefef;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-weight: bold;
  
  span.contact {
    flex-grow: 1;
    text-align: right;
  }

  .btn {
    background: #EA6E30;
    color: white;
    text-shadow: none;
    transition: background 0.2s;

    &:hover {
      background: darken(#EA6E30, 10%);
    }
  }
}

// Modal Contact Popup
.modal-body{
  max-height: 600px;
}

// Analyte No Result
.facet-noresult{
  height: 130px;
}
// Analyte No Result - Center Text
div.view-analyte-search > div.view-empty{
  text-align: center;
}

.btn a:hover{
  color: #ffffff;
}