.wizard,
.tabcontrol {
  display: block;
  width: 100%;
  overflow: hidden
}

.wizard a,
.tabcontrol a {
  outline: 0
}

.wizard ul,
.tabcontrol ul {
  list-style: none!important;
  padding: 0;
  margin: 0
}

.wizard ul>li,
.tabcontrol ul>li {
  display: block;
  padding: 0
}

.wizard>.steps .current-info,
.tabcontrol>.steps .current-info {
  position: absolute;
  left: -999em
}

.wizard>.content>.title,
.tabcontrol>.content>.title {
  position: absolute;
  left: -999em
}

.wizard>.steps {
  position: relative;
  display: block;
  width: 100%
}

.wizard.vertical>.steps {
  display: inline;
  float: left;
  width: 30%
}

.wizard>.steps .number {
  font-size: 1.429em
}

.wizard>.steps>ul>li {
  width: 25%
}

.wizard>.steps>ul>li,
.wizard>.actions>ul>li {
  float: left
}

.wizard.vertical>.steps>ul>li {
  float: none;
  width: 100%
}

.wizard>.steps a,
.wizard>.steps a:hover,
.wizard>.steps a:active {
  display: block;
  width: auto;
  margin: 0 .5em .5em;
  padding: 1em 1em;
  text-decoration: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px
}

.wizard>.steps .disabled a,
.wizard>.steps .disabled a:hover,
.wizard>.steps .disabled a:active {
  background: #eee;
  color: #aaa;
  cursor: default
}

.wizard>.steps .current a,
.wizard>.steps .current a:hover,
.wizard>.steps .current a:active {
  background: #2184be;
  color: #fff;
  cursor: default
}

.wizard>.steps .done a,
.wizard>.steps .done a:hover,
.wizard>.steps .done a:active {
  background: #9dc8e2;
  color: #fff
}

.wizard>.steps .error a,
.wizard>.steps .error a:hover,
.wizard>.steps .error a:active {
  background: #ff3111;
  color: #fff
}

.wizard>.content {
  background: #eee;
  display: block;
  margin: .5em;
  min-height: 35em;
  overflow: hidden;
  position: relative;
  width: auto;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px
}

.wizard.vertical>.content {
  display: inline;
  float: left;
  margin: 0 2.5% .5em 2.5%;
  width: 65%
}

.wizard>.content>.body {
  width: 95%;
  padding: 2.5%;

  fieldset {
    margin-bottom: 30px;
  }
}

.wizard>.content>.body ul {
  list-style: disc!important
}

.wizard>.content>.body ul>li {
  display: list-item
}

.wizard>.content>.body>iframe {
  border: 0 none;
  width: 100%;
  height: 100%
}

.wizard>.content>.body input {
  /*display: block;*/
  border: 1px solid #ccc
}

.wizard>.content>.body input[type="checkbox"] {
  display: inline-block
}

.wizard>.content>.body input.error {
  background: #fbe3e4;
  border: 1px solid #fbc2c4;
  color: #8a1f11
}

.wizard>.content>.body {

  label {
    display: inline-block;
    margin-bottom: .5em;
    width: 25%;
  }

  .form-item-panes-delivery-copy-address, .form-item-panes-uc-ct-uc-tc-agree-uc-tc {
    label {
    width: 100%;
    display: inline;
    }
  }

  .form-item-panes-payment-details-po-number {
    label {
      width: 10%;
    }
  }

  .form-item-panes-quotes-quotes-fedex-account-number,
  .form-item-panes-quotes-quotes-carrier,
  .form-item-panes-quotes-quotes-carrier-account-number {
    label { width: auto; }
  }

  .payment-details-credit  label { width: 10em; }
}


.wizard>.content>.body label.error {
  color: #8a1f11;
  display: inline-block;
  margin-left: 1.5em
}

.wizard>.actions {
  position: relative;
  display: block;
  text-align: right;
  width: 100%
}

.wizard.vertical>.actions {
  display: inline;
  float: right;
  margin: 0 2.5%;
  width: 95%
}

.wizard>.actions>ul {
  display: inline-block;
  text-align: right
}

.wizard>.actions>ul>li {
  margin: 0 .5em
}

.wizard.vertical>.actions>ul>li {
  margin: 0 0 0 1em
}

.wizard>.actions a,
.wizard>.actions a:hover,
.wizard>.actions a:active {
  background: #2184be;
  color: #fff;
  display: block;
  padding: .5em 1em;
  text-decoration: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px
}

.wizard>.actions .disabled a,
.wizard>.actions .disabled a:hover,
.wizard>.actions .disabled a:active {
  background: #eee;
  color: #aaa
}
input#edit-continue {
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
}
