/* Product Tabs */
$tabs-breakpoint: 600px;

.tab-container {

  > ul {
    border-bottom: 0;
    overflow: hidden;
    position: relative;
    background: #CCC;
    background: linear-gradient(#EFEFEF, #CCC);
    margin: 0;
    padding: 18px;
    
    &::after {
      content: "☰"; 
      display: block;
      position: absolute;
      top: 8px;
      right: 15px;
      z-index: 2;
      pointer-events: none;
    }

    @include breakpoint($tabs-breakpoint) {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      border-bottom: 1px solid #CCC;
      background: none;
      &::after {
        display: none;
      }
    }
  }

  li.tab {
    margin-right: 2px;
    list-style-type: none;
    @include breakpoint($tabs-breakpoint) {
      padding: 0;
    }

    a {
      display: block;
      font-weight: bold;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 0.5rem;

      @include breakpoint($tabs-breakpoint) {
        display: block;
        position: relative;
        border: 1px solid #CCC;
        border-bottom: none;
        width: auto;
        height: auto;
        background: #EFEFEF;
        padding: 10px 15px;

        &:hover {
          background-color: #FFF;
        }
      }

      &.active {
        border: 0;
        z-index: 1;
        background: linear-gradient(#EFEFEF, #CCC);
        @include breakpoint($tabs-breakpoint) {
          display: block;
          z-index: 0;
          background: #FFF;
          border: 1px solid #CCC;
          border-bottom: none;
        }
      }
    }
  }

  .tab-content-container {
    border: 1px solid #CCC;
    border-top: none;

    table {
      width: auto !important;
    }
  }

  ul.open {
    padding: 0;
    a {
      position: relative;
      display: block;
    }
  }
}
