.tech-info-data--images {
  .field-type-image {
    border: none;
    border-radius: 0;
  }
  .field-items {
    display: flex;

    > div {
      margin-right: 10px;
      border: 1px solid #333;
    }
  }
}

#cboxOverlay {
  background: rgba(0,0,0,0.9);
}

#cboxWrapper {
  background: none;
  border-radius: 0;
  #cboxTopLeft,
  #cboxTopRight,
  #cboxTopCenter,
  #cboxMiddleLeft,
  #cboxMiddleRight,
  #cboxBottomLeft,
  #cboxBottomRight,
  #cboxBottomCenter {
    display: none;
  }
}

#cboxTitle {
  position: static;
  text-align: left;
  color: #fff;
  font-weight: bold;
  width: 100%;
  font-size: 16px;
}

#cboxContent {
  padding-bottom: 60px;
  padding-top: 30px;
  background: none;
}

#cboxLoadedContent {
  overflow: auto !important;
  margin-bottom: 10px;
}

#cboxClose {
  float: right;
  top: 0;
  bottom: auto;
  background: none;
  width: auto;
  height: auto;
  color: #fff;
  font-weight: bold;
  text-indent: 0;
  font-size: 16px;
  text-transform: uppercase;
}
