/*div.modal-forms-modal-content {
  .popups-container {
    background: #00284d;
  }

  .modal-title {
    color: #FFF;
    display: inline-block;
    padding-top: 2em;
  }

  #webform-client-form-2198 {
    margin: auto;
    width: 70%;
    color: #FFF;

    input[type] {
      padding: 6px;
    }

    ::placeholder {
      color: #6f6f6f;
      font-weight: bold;
    }

    input.webform-submit {
      padding: 5px 40px;
      background: #0678be;
      color: #fff;
      font-size: 14px;
      text-transform: uppercase;
    }

    .form-actions {
      text-align: center;
    }

    input[type="checkbox"] {
      width: 1.25rem;
      height: 1.25rem;
      margin-top: 10px;
      margin-right: 10px;
    }

    .webform-component--newsletter-signup {
      .form-type-checkbox {
        display: inline-block;
        height: 20px;
      }

      .description {
        margin: 0 auto;
        width: 85%;
      }
    }
  }
}

.popups-title {
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  border-bottom: none;
}
*/
