.landing-page-header-container {
  h1 {
    margin-top: 0;
    line-height: 100%;
    color: #002876;
    font-weight: 700;
  }

  .page_title {
    color: #002876;
    font-weight: 700;
    font-size: 26px;
    line-height: 100%;
    margin-bottom: 20px;
  }

  .hr {
    line-height: 10px;
    height: 3px;
    background: #0678be;
    margin-bottom: 10px;
  }

  .field-name-field-additional-names {
    .field-items {
      margin-bottom: 0px;
    }
  }

  .btn-read-more a {
    padding: 7px 10px;
    background: #002876;
    color: #fff;
    font-size: 18px;
    float: right;
  }

  .btn-contact-us a {
    padding: 7px 10px;
    background: #FF5C33;
    color: #fff;
    font-size: 18px;
    float: left;
  }

  .hr.separator {
    margin: 30px 0;
  }
}

.node-type-landing-page {
  .moscone-flipped-sidebar {

    .moscone-flipped-sidebar-inner {
      border-left: 1px solid #FFA500;
      padding-left: 50px;
      padding-bottom: 25px;
    }

    h2.pane-title {
      font-size: 17px;
      font-weight: bold;
      margin: 2px 0px 20px 0px;
      color: #002876;
      line-height: 100%;
    }

    .field-item {
      a {
        color: #333;
      }
    }
  }

  .pane-node-field-product-display {
    .product-summary {
      p {
        display: inline;
      }
    }

    .product-links {
      background: #FF5C33;
      color: #fff;
      font-size: 18px;

      .field-item {
        text-align: center;
        margin: 0 auto;
        line-height: 30px;

        a {
          color: #FFF;
        }
      }
    }

    .product-model {
      text-align: center;
    }

    .product-hr {
      border-bottom: 1px #0678be solid;
      margin: 10px 0;
      margin-left: 25px;
    }

    .field-name-field-product-information {
      .field-item {
        margin: 0;
      }
    }
  }

  .paragraphs-item-description-image {
    margin: 0;
  }

  .contact-cta {
    display: inline-block;
    margin-top: 20px;
    padding: 5px 40px;
    background: #0678be;
    color: #fff;
    font-size: 14px;
  }
}
