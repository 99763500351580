  .technical-resource-custom {

    h1 {
    margin-top: 0;
    line-height: 100%;
    color: #002876;
    font-weight: 700;
    }

    .page_title {
        color: #002876;
        font-weight: 700;
        font-size: 26px;
        line-height: 100%;
        margin-bottom: 20px;
    }

    .field-type-taxonomy-term-reference {
        .field-items {

            .field-item {
                padding-left: 0px;
                padding-right: 5px;
                margin-bottom: 0px;
                background: none;
            }

        }
    }
    .field-name-field-authors {
        .field-items {
            margin-bottom: 0x;
        }
    }

    aside.tr-sidebar {
        border-left: 1px solid orange;
        padding-left: 50px;
        padding-bottom: 25px;

        .tr-sb-section {
            margin-bottom: 20px;

            &.last {
                max-width: 210px;
            }
            .field-label {
                font-size: 17px;
                font-weight: bold;
                margin: 2px 0px 20px 0px;
                color: #002876;
                line-height: 100%;
            }

            .field-type-link-field {
                .field-items {
                    margin-bottom: 0px;
                    .field-item {
                        margin-bottom: 10px;
                        
                        a {
                            color: #333;
                        }
                    }
                    
                }
            }

            .contact-cta {
                display: inline-block;
                margin-top: 20px;
                padding: 5px 40px;
                background: #0678be;;
                color: #fff;
                font-size: 14px;
            }

        }
    }

}
.field-type-user-reference .field-items .field-item {
    margin-bottom: 15px;
    display: inline-block;
    margin: 0px 5px;
}
.technical-resource-custom  .hr {
    line-height: 10px;
    height: 10px;
    background: #0678be;
    margin-bottom: 10px;
}
.technical-resource-custom .hr.seperator{}
.technical-resource-custom .btn-orange {
    padding: 7px 10px;
    background: orange;
    color: #fff;
    font-size: 18px;
    display: inline-block;
}
.technical-resource-custom .btn-read-on-site {
    padding: 7px 10px;
    background: #0678be;;
    color: #fff;
    font-size: 18px;
    float: right;
}
.summary-button {
    margin-top: 10px;
    margin-bottom: 10px;
}
.hr.seperator {
    margin-bottom: 30px;
}
.paragraphs-item-description-image {
    margin: 15px;

    img {
        width: auto;
    }

    .field-type-list-text {
        display: none;
    }
}

.float-left { 
    img {
        float:left;
    }

}

.float-right {
    img {
        float:right;
    }
}

.float-center { 
    img {
        display:block;
        margin-left:auto;
        margin-right:auto;
    }
}

/*For mobile */
@media only screen 
and (min-device-width : 300px) 
and (max-device-width : 812px)
and (orientation : portrait) {
    .technical-resource-custom {
        .main-image {
            display: none;
            visibility: hidden;
        }
        .field-name-field-image {
            display: none;
            visibility: hidden;
        }
        .page_title {
            margin-top: -20px;
        }
        .btn-orange {
            width: 100%;
            padding: 7px 0px;
            text-align: center;
        }

        .btn-read-on-site {
            width: 100%;
            padding: 7px 0px;
            text-align: center;
        }
    }
}

/*For mobile potrait mode*/


/*For tablet*/
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px)  {
    .technical-resource-custom {
        .main-image {
            display: block;
            visibility: visible;
        }
        .field-name-field-image {
            display: block;
            visibility: visible;
        }
        .btn-orange {
            width: auto;
            padding: 7px 10px;
            text-align: left;
        }

        .btn-read-on-site {
            width: auto;
            padding: 7px 10px;
            text-align: left;
        }
    }
    
}