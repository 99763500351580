@charset "UTF-8";
/* Product Tabs */
.tab-container > ul {
  border-bottom: 0;
  overflow: hidden;
  position: relative;
  background: #CCC;
  background: linear-gradient(#EFEFEF, #CCC);
  margin: 0;
  padding: 18px; }
  .tab-container > ul::after {
    content: "☰";
    display: block;
    position: absolute;
    top: 8px;
    right: 15px;
    z-index: 2;
    pointer-events: none; }
  @media (min-width: 600px) {
    .tab-container > ul {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      border-bottom: 1px solid #CCC;
      background: none; }
      .tab-container > ul::after {
        display: none; } }

.tab-container li.tab {
  margin-right: 2px;
  list-style-type: none; }
  @media (min-width: 600px) {
    .tab-container li.tab {
      padding: 0; } }
  .tab-container li.tab a {
    display: block;
    font-weight: bold;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0.5rem; }
    @media (min-width: 600px) {
      .tab-container li.tab a {
        display: block;
        position: relative;
        border: 1px solid #CCC;
        border-bottom: none;
        width: auto;
        height: auto;
        background: #EFEFEF;
        padding: 10px 15px; }
        .tab-container li.tab a:hover {
          background-color: #FFF; } }
    .tab-container li.tab a.active {
      border: 0;
      z-index: 1;
      background: linear-gradient(#EFEFEF, #CCC); }
      @media (min-width: 600px) {
        .tab-container li.tab a.active {
          display: block;
          z-index: 0;
          background: #FFF;
          border: 1px solid #CCC;
          border-bottom: none; } }

.tab-container .tab-content-container {
  border: 1px solid #CCC;
  border-top: none; }
  .tab-container .tab-content-container table {
    width: auto !important; }

.tab-container ul.open {
  padding: 0; }
  .tab-container ul.open a {
    position: relative;
    display: block; }

.tech-info-data--images .field-type-image {
  border: none;
  border-radius: 0; }

.tech-info-data--images .field-items {
  display: flex; }
  .tech-info-data--images .field-items > div {
    margin-right: 10px;
    border: 1px solid #333; }

#cboxOverlay {
  background: rgba(0, 0, 0, 0.9); }

#cboxWrapper {
  background: none;
  border-radius: 0; }
  #cboxWrapper #cboxTopLeft,
  #cboxWrapper #cboxTopRight,
  #cboxWrapper #cboxTopCenter,
  #cboxWrapper #cboxMiddleLeft,
  #cboxWrapper #cboxMiddleRight,
  #cboxWrapper #cboxBottomLeft,
  #cboxWrapper #cboxBottomRight,
  #cboxWrapper #cboxBottomCenter {
    display: none; }

#cboxTitle {
  position: static;
  text-align: left;
  color: #fff;
  font-weight: bold;
  width: 100%;
  font-size: 16px; }

#cboxContent {
  padding-bottom: 60px;
  padding-top: 30px;
  background: none; }

#cboxLoadedContent {
  overflow: auto !important;
  margin-bottom: 10px; }

#cboxClose {
  float: right;
  top: 0;
  bottom: auto;
  background: none;
  width: auto;
  height: auto;
  color: #fff;
  font-weight: bold;
  text-indent: 0;
  font-size: 16px;
  text-transform: uppercase; }

/* Analyte Search */
.page-analyte-search #zone2 {
  padding-top: 15px; }

.page-analyte-search p.description {
  font-size: 11px;
  line-height: 14px;
  color: gray; }

#block-views-exp-analyte-search-page {
  margin-top: 5px; }

#block-views-exp-analyte-search-page .blocktitle, [id^="block-facetapi-"] .blocktitle {
  background-color: #002876;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 10px; }

#facetapi-facet-search-apianalyte-search-block-field-analytes {
  max-height: 150px;
  overflow-y: auto; }

.form-item-field-analytes, .form-item-field-plex, .form-item-field-species {
  margin-top: 0em;
  margin-bottom: 0em; }

#block-views-exp-analyte-search-page, #block-facetapi-mcwad9dwsg6fyyoh2zpxpt2xf8vc0aos, #block-facetapi-jwpefevrc25i1t7mu7tpvlpwq1umnkga, #block-facetapi-1r7i9x1kdmu0aci9ezazkwmrthdxuurc, #block-facetapi-w7pjsrymlzfnibsk0qxnox0yveojns2z {
  margin-bottom: 0px !important; }

#block-system-main > div > div.view-analyte-search > div.view-content {
  max-height: 575px;
  overflow-y: scroll;
  overflow-x: hidden; }

#block-system-main > div > div.view-analyte-search > div.view-content.expanded {
  max-height: 400px; }

#block-system-main > div > div.view-analyte-search > div.view-content > table > thead {
  display: block; }

#block-system-main > div > div.view-analyte-search > div.view-content > table > tbody {
  max-height: 600px;
  display: block;
  overflow-y: scroll;
  overflow-x: hidden; }

.views-widget-filter-search_api_views_fulltext {
  width: 96%; }

#views-exposed-form-analyte-search-page .views-exposed-widget {
  padding: 0; }

#views-exposed-form-analyte-search-page input.btn {
  margin: 15px 10px 0 0;
  width: 100px; }

.btn-theme > a {
  color: #ffffff; }

.view-analyte-search .views-row-odd {
  background: #eee; }

.view-analyte-search .views-row {
  padding: 5px 10px; }

.analyte-search-result {
  padding: 5px 0; }
  @media (min-width: 1118px) {
    .analyte-search-result {
      display: flex; } }
  @media (min-width: 1118px) {
    .analyte-search-result__title {
      width: 300px; } }
  .analyte-search-result__terms {
    display: flex;
    flex-wrap: wrap; }
  .analyte-search-result__term {
    width: 75px;
    min-width: 75px;
    margin-right: 10px;
    margin-top: 10px; }
    @media (min-width: 1118px) {
      .analyte-search-result__term {
        margin: 0 20px 0 0; } }
    .analyte-search-result__term .btn {
      width: 100%;
      box-sizing: border-box;
      background: #286ea0;
      background: linear-gradient(54deg, #286ea0 0%, #37a6e7 50%, #00d4ff 100%);
      border-color: #74b3e0; }
  .analyte-search-result__ghost-btn {
    width: 75px;
    height: 24px;
    border: 1px dashed #ccc;
    border-radius: 2px; }

.view-analyte-search .preview-container h2 {
  margin-bottom: 10px; }

.view-analyte-search .preview-container__analytes {
  margin-bottom: 0px; }
  .view-analyte-search .preview-container__analytes span:after {
    content: ', '; }
  .view-analyte-search .preview-container__analytes span:last-child:after {
    content: ''; }

.view-analyte-search .preview-container__description {
  margin: 10px 0 20px 0; }

.analyte-search__header {
  margin-top: 10px;
  font-weight: bold;
  background: #002876;
  color: white;
  padding: 5px 10px;
  display: flex; }
  .analyte-search__header .desc-col {
    width: 300px; }
  .analyte-search__header .as-col {
    width: 75px;
    margin-right: 1.44em;
    text-align: center;
    display: none; }
    @media (min-width: 1118px) {
      .analyte-search__header .as-col {
        display: block; } }

.analyte-search__contact {
  margin-top: 20px;
  padding: 5px 10px;
  background: #efefef;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-weight: bold; }
  .analyte-search__contact span.contact {
    flex-grow: 1;
    text-align: right; }
  .analyte-search__contact .btn {
    background: #EA6E30;
    color: white;
    text-shadow: none;
    transition: background 0.2s; }
    .analyte-search__contact .btn:hover {
      background: #d25415; }

.modal-body {
  max-height: 600px; }

.facet-noresult {
  height: 130px; }

div.view-analyte-search > div.view-empty {
  text-align: center; }

.btn a:hover {
  color: #ffffff; }

.wizard,
.tabcontrol {
  display: block;
  width: 100%;
  overflow: hidden; }

.wizard a,
.tabcontrol a {
  outline: 0; }

.wizard ul,
.tabcontrol ul {
  list-style: none !important;
  padding: 0;
  margin: 0; }

.wizard ul > li,
.tabcontrol ul > li {
  display: block;
  padding: 0; }

.wizard > .steps .current-info,
.tabcontrol > .steps .current-info {
  position: absolute;
  left: -999em; }

.wizard > .content > .title,
.tabcontrol > .content > .title {
  position: absolute;
  left: -999em; }

.wizard > .steps {
  position: relative;
  display: block;
  width: 100%; }

.wizard.vertical > .steps {
  display: inline;
  float: left;
  width: 30%; }

.wizard > .steps .number {
  font-size: 1.429em; }

.wizard > .steps > ul > li {
  width: 25%; }

.wizard > .steps > ul > li,
.wizard > .actions > ul > li {
  float: left; }

.wizard.vertical > .steps > ul > li {
  float: none;
  width: 100%; }

.wizard > .steps a,
.wizard > .steps a:hover,
.wizard > .steps a:active {
  display: block;
  width: auto;
  margin: 0 .5em .5em;
  padding: 1em 1em;
  text-decoration: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }

.wizard > .steps .disabled a,
.wizard > .steps .disabled a:hover,
.wizard > .steps .disabled a:active {
  background: #eee;
  color: #aaa;
  cursor: default; }

.wizard > .steps .current a,
.wizard > .steps .current a:hover,
.wizard > .steps .current a:active {
  background: #2184be;
  color: #fff;
  cursor: default; }

.wizard > .steps .done a,
.wizard > .steps .done a:hover,
.wizard > .steps .done a:active {
  background: #9dc8e2;
  color: #fff; }

.wizard > .steps .error a,
.wizard > .steps .error a:hover,
.wizard > .steps .error a:active {
  background: #ff3111;
  color: #fff; }

.wizard > .content {
  background: #eee;
  display: block;
  margin: .5em;
  min-height: 35em;
  overflow: hidden;
  position: relative;
  width: auto;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }

.wizard.vertical > .content {
  display: inline;
  float: left;
  margin: 0 2.5% .5em 2.5%;
  width: 65%; }

.wizard > .content > .body {
  width: 95%;
  padding: 2.5%; }
  .wizard > .content > .body fieldset {
    margin-bottom: 30px; }

.wizard > .content > .body ul {
  list-style: disc !important; }

.wizard > .content > .body ul > li {
  display: list-item; }

.wizard > .content > .body > iframe {
  border: 0 none;
  width: 100%;
  height: 100%; }

.wizard > .content > .body input {
  /*display: block;*/
  border: 1px solid #ccc; }

.wizard > .content > .body input[type="checkbox"] {
  display: inline-block; }

.wizard > .content > .body input.error {
  background: #fbe3e4;
  border: 1px solid #fbc2c4;
  color: #8a1f11; }

.wizard > .content > .body label {
  display: inline-block;
  margin-bottom: .5em;
  width: 25%; }

.wizard > .content > .body .form-item-panes-delivery-copy-address label, .wizard > .content > .body .form-item-panes-uc-ct-uc-tc-agree-uc-tc label {
  width: 100%;
  display: inline; }

.wizard > .content > .body .form-item-panes-payment-details-po-number label {
  width: 10%; }

.wizard > .content > .body .form-item-panes-quotes-quotes-fedex-account-number label,
.wizard > .content > .body .form-item-panes-quotes-quotes-carrier label,
.wizard > .content > .body .form-item-panes-quotes-quotes-carrier-account-number label {
  width: auto; }

.wizard > .content > .body .payment-details-credit label {
  width: 10em; }

.wizard > .content > .body label.error {
  color: #8a1f11;
  display: inline-block;
  margin-left: 1.5em; }

.wizard > .actions {
  position: relative;
  display: block;
  text-align: right;
  width: 100%; }

.wizard.vertical > .actions {
  display: inline;
  float: right;
  margin: 0 2.5%;
  width: 95%; }

.wizard > .actions > ul {
  display: inline-block;
  text-align: right; }

.wizard > .actions > ul > li {
  margin: 0 .5em; }

.wizard.vertical > .actions > ul > li {
  margin: 0 0 0 1em; }

.wizard > .actions a,
.wizard > .actions a:hover,
.wizard > .actions a:active {
  background: #2184be;
  color: #fff;
  display: block;
  padding: .5em 1em;
  text-decoration: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }

.wizard > .actions .disabled a,
.wizard > .actions .disabled a:hover,
.wizard > .actions .disabled a:active {
  background: #eee;
  color: #aaa; }

input#edit-continue {
  width: 0;
  height: 0;
  margin: 0;
  padding: 0; }

.technical-resource-custom h1 {
  margin-top: 0;
  line-height: 100%;
  color: #002876;
  font-weight: 700; }

.technical-resource-custom .page_title {
  color: #002876;
  font-weight: 700;
  font-size: 26px;
  line-height: 100%;
  margin-bottom: 20px; }

.technical-resource-custom .field-type-taxonomy-term-reference .field-items .field-item {
  padding-left: 0px;
  padding-right: 5px;
  margin-bottom: 0px;
  background: none; }

.technical-resource-custom .field-name-field-authors .field-items {
  margin-bottom: 0x; }

.technical-resource-custom aside.tr-sidebar {
  border-left: 1px solid orange;
  padding-left: 50px;
  padding-bottom: 25px; }
  .technical-resource-custom aside.tr-sidebar .tr-sb-section {
    margin-bottom: 20px; }
    .technical-resource-custom aside.tr-sidebar .tr-sb-section.last {
      max-width: 210px; }
    .technical-resource-custom aside.tr-sidebar .tr-sb-section .field-label {
      font-size: 17px;
      font-weight: bold;
      margin: 2px 0px 20px 0px;
      color: #002876;
      line-height: 100%; }
    .technical-resource-custom aside.tr-sidebar .tr-sb-section .field-type-link-field .field-items {
      margin-bottom: 0px; }
      .technical-resource-custom aside.tr-sidebar .tr-sb-section .field-type-link-field .field-items .field-item {
        margin-bottom: 10px; }
        .technical-resource-custom aside.tr-sidebar .tr-sb-section .field-type-link-field .field-items .field-item a {
          color: #333; }
    .technical-resource-custom aside.tr-sidebar .tr-sb-section .contact-cta {
      display: inline-block;
      margin-top: 20px;
      padding: 5px 40px;
      background: #0678be;
      color: #fff;
      font-size: 14px; }

.field-type-user-reference .field-items .field-item {
  margin-bottom: 15px;
  display: inline-block;
  margin: 0px 5px; }

.technical-resource-custom .hr {
  line-height: 10px;
  height: 10px;
  background: #0678be;
  margin-bottom: 10px; }

.technical-resource-custom .btn-orange {
  padding: 7px 10px;
  background: orange;
  color: #fff;
  font-size: 18px;
  display: inline-block; }

.technical-resource-custom .btn-read-on-site {
  padding: 7px 10px;
  background: #0678be;
  color: #fff;
  font-size: 18px;
  float: right; }

.summary-button {
  margin-top: 10px;
  margin-bottom: 10px; }

.hr.seperator {
  margin-bottom: 30px; }

.paragraphs-item-description-image {
  margin: 15px; }
  .paragraphs-item-description-image img {
    width: auto; }
  .paragraphs-item-description-image .field-type-list-text {
    display: none; }

.float-left img {
  float: left; }

.float-right img {
  float: right; }

.float-center img {
  display: block;
  margin-left: auto;
  margin-right: auto; }

/*For mobile */
@media only screen and (min-device-width: 300px) and (max-device-width: 812px) and (orientation: portrait) {
  .technical-resource-custom .main-image {
    display: none;
    visibility: hidden; }
  .technical-resource-custom .field-name-field-image {
    display: none;
    visibility: hidden; }
  .technical-resource-custom .page_title {
    margin-top: -20px; }
  .technical-resource-custom .btn-orange {
    width: 100%;
    padding: 7px 0px;
    text-align: center; }
  .technical-resource-custom .btn-read-on-site {
    width: 100%;
    padding: 7px 0px;
    text-align: center; } }

/*For mobile potrait mode*/
/*For tablet*/
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .technical-resource-custom .main-image {
    display: block;
    visibility: visible; }
  .technical-resource-custom .field-name-field-image {
    display: block;
    visibility: visible; }
  .technical-resource-custom .btn-orange {
    width: auto;
    padding: 7px 10px;
    text-align: left; }
  .technical-resource-custom .btn-read-on-site {
    width: auto;
    padding: 7px 10px;
    text-align: left; } }

.landing-page-header-container h1 {
  margin-top: 0;
  line-height: 100%;
  color: #002876;
  font-weight: 700; }

.landing-page-header-container .page_title {
  color: #002876;
  font-weight: 700;
  font-size: 26px;
  line-height: 100%;
  margin-bottom: 20px; }

.landing-page-header-container .hr {
  line-height: 10px;
  height: 3px;
  background: #0678be;
  margin-bottom: 10px; }

.landing-page-header-container .field-name-field-additional-names .field-items {
  margin-bottom: 0px; }

.landing-page-header-container .btn-read-more a {
  padding: 7px 10px;
  background: #002876;
  color: #fff;
  font-size: 18px;
  float: right; }

.landing-page-header-container .btn-contact-us a {
  padding: 7px 10px;
  background: #FF5C33;
  color: #fff;
  font-size: 18px;
  float: left; }

.landing-page-header-container .hr.separator {
  margin: 30px 0; }

.node-type-landing-page .moscone-flipped-sidebar .moscone-flipped-sidebar-inner {
  border-left: 1px solid #FFA500;
  padding-left: 50px;
  padding-bottom: 25px; }

.node-type-landing-page .moscone-flipped-sidebar h2.pane-title {
  font-size: 17px;
  font-weight: bold;
  margin: 2px 0px 20px 0px;
  color: #002876;
  line-height: 100%; }

.node-type-landing-page .moscone-flipped-sidebar .field-item a {
  color: #333; }

.node-type-landing-page .pane-node-field-product-display .product-summary p {
  display: inline; }

.node-type-landing-page .pane-node-field-product-display .product-links {
  background: #FF5C33;
  color: #fff;
  font-size: 18px; }
  .node-type-landing-page .pane-node-field-product-display .product-links .field-item {
    text-align: center;
    margin: 0 auto;
    line-height: 30px; }
    .node-type-landing-page .pane-node-field-product-display .product-links .field-item a {
      color: #FFF; }

.node-type-landing-page .pane-node-field-product-display .product-model {
  text-align: center; }

.node-type-landing-page .pane-node-field-product-display .product-hr {
  border-bottom: 1px #0678be solid;
  margin: 10px 0;
  margin-left: 25px; }

.node-type-landing-page .pane-node-field-product-display .field-name-field-product-information .field-item {
  margin: 0; }

.node-type-landing-page .paragraphs-item-description-image {
  margin: 0; }

.node-type-landing-page .contact-cta {
  display: inline-block;
  margin-top: 20px;
  padding: 5px 40px;
  background: #0678be;
  color: #fff;
  font-size: 14px; }

/*div.modal-forms-modal-content {
  .popups-container {
    background: #00284d;
  }

  .modal-title {
    color: #FFF;
    display: inline-block;
    padding-top: 2em;
  }

  #webform-client-form-2198 {
    margin: auto;
    width: 70%;
    color: #FFF;

    input[type] {
      padding: 6px;
    }

    ::placeholder {
      color: #6f6f6f;
      font-weight: bold;
    }

    input.webform-submit {
      padding: 5px 40px;
      background: #0678be;
      color: #fff;
      font-size: 14px;
      text-transform: uppercase;
    }

    .form-actions {
      text-align: center;
    }

    input[type="checkbox"] {
      width: 1.25rem;
      height: 1.25rem;
      margin-top: 10px;
      margin-right: 10px;
    }

    .webform-component--newsletter-signup {
      .form-type-checkbox {
        display: inline-block;
        height: 20px;
      }

      .description {
        margin: 0 auto;
        width: 85%;
      }
    }
  }
}

.popups-title {
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  border-bottom: none;
}
*/
